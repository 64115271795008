<script setup lang="ts">
defineProps({
  title: {
    type: String,
    default: 'Features'
  },
  columns: {
    type: String,
    default: '3'
  },
  class: {
    type: String,
    default: ''
  },
  gapless: {
    type: Boolean,
    default: false
  }
})
// Tailwind does not allow dynamic classes
const columnsVariants = {
  '1': 'lg:grid-cols-1',
  '2': 'lg:grid-cols-2',
  '3': 'lg:grid-cols-3',
  '4': 'lg:grid-cols-4',
  '5': 'lg:grid-cols-5'
}
</script>

<template>
  <section class="relative mt-4" :class="class">
    <div class="grid content-center font-semibold text-left u-text-gray-900 grid-cols-1 "
      :class="[columns ? columnsVariants[columns] : `lg:grid-cols-3`, gapless ? 'gap-0 2xl:gap-0' : 'gap-4 2xl:gap-8']">
      <slot />
    </div>
  </section>
</template>
